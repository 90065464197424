import React, { ReactNode } from 'react';

import ExternalLink from 'common-ui-components/ExternalLink';
import { articleConfig } from 'common-ui-components/IntercomArticleButton';
import App, { AkoodaApiApp } from 'global/lists/apps';
import getAppDisplayName from 'global/lists/appsNames';
import { Connector } from 'screens/settings/AppsScreen/components/ConnectorRow';
import GmailConnectedEmailPopover
  from 'screens/settings/AppsScreen/components/ConnectorRow/components/GmailConnectedEmailPopover';
import ArrayUtils from 'utils/ArrayUtils';

import bambooLogo from 'assets/img/logo/bamboo.svg';
import hibobLogo from 'assets/img/logo/hibob.svg';
import jsmIcon from 'assets/img/logo/jsm.svg';
import m365Icon from 'assets/img/logo/m365.svg';
import m365exchangeIcon from 'assets/img/logo/m365exchange.svg';
import miroLogo from 'assets/img/logo/miro.svg';
import oktaLogo from 'assets/img/logo/okta.svg';
import shortcutLogo from 'assets/img/logo/shortcut.svg';

const PLATFORM_GUIDE_STEPS = {
  GOOGLE: {
    DEFAULT: getAccountSetupSteps('Google', { isEmail: false }),
    EMAIL: getAccountSetupSteps('Google', { isEmail: true }),
  },
  M365: {
    DEFAULT: getAccountSetupSteps('M365', { isEmail: false }),
    EMAIL: getAccountSetupSteps('M365', { isEmail: true }),
  },
};

function getAccountSetupSteps(platform: 'Google' | 'M365', options: { isEmail: boolean }) {
  const commonSteps: ReactNode[] = [
    `Create a new akooda@yourcompany.com account in ${platform} (if you haven't already)`,
    `Give the new Akooda ${platform} Account the correct Account Roles ${platform === 'M365' && !options.isEmail ? '(application administrator)' : 'and Permissions (if you haven\'t already)'}`,
    platform === 'M365' && !options.isEmail ? (
      <span key="m365_step">
        Grant admin consent to akooda app
        <ExternalLink to="https://login.microsoftonline.com/organizations/adminconsent?client_id=a9c2265f-fe60-4bfb-8755-4f2c18c6cd69&redirect_uri=https://app.akooda.co/public/connector/250/success"> here</ExternalLink>
      </span>
    ) : null,
    'Login to Akooda with your admin account',
    `Link the new Akooda ${platform} Account with Akooda`,
  ];

  const finalStep = options.isEmail
    ? 'Use the akooda@yourcompany.com inbox to digest selected emails - setup automatic routing rule or add as cc/bcc recipient'
    : `Share the right ${platform === 'Google' ? 'files and drives' : 'Sites, Groups, Folders and Documents'} with akooda@yourcompany.com`;

  return [...commonSteps.filter(ArrayUtils.isDefined), finalStep];
}

const CONNECTORS: Connector[] = [
  {
    name: 'Akooda AI Solutions',
    appId: App.AKOODA,
  },
  {
    name: getAppDisplayName(App.SLACK),
    appId: App.SLACK,
    additionalTitleText: '(Required)',
    articleConfig: articleConfig.ConnectorSlack,
    guideSteps: [
      "Make sure you're an Admin user in Slack",
      'Link your Admin slack user with Akooda',
    ],
  }, {
    name: getAppDisplayName(App.DROPBOX),
    appId: App.DROPBOX,
    articleConfig: articleConfig.ConnectorDropbox,
    guideSteps: [
      'Create a new akooda@yourcompany.com account in Dropbox',
      'Give the new Akooda Dropbox Account the correct Account Roles and Permissions',
      'Link the new Akooda Dropbox Account account with Akooda',
      'Share the right files and drives with akooda@yourcompany.com',
    ],
  }, {
    name: getAppDisplayName(App.GOOGLE_DRIVE),
    logoAppId: App.GOOGLE_DRIVE,
    appId: App.GOOGLE,
    articleConfig: articleConfig.ConnectorGoogleWorkspace,
    guideSteps: PLATFORM_GUIDE_STEPS.GOOGLE.DEFAULT,
  }, {
    name: getAppDisplayName(App.GOOGLE_CALENDAR),
    logoAppId: App.GOOGLE_CALENDAR,
    appId: App.GOOGLE,
    articleConfig: articleConfig.ConnectorGoogleWorkspace,
    guideSteps: PLATFORM_GUIDE_STEPS.GOOGLE.DEFAULT,
  }, {
    name: getAppDisplayName(App.GOOGLE_MEET),
    logoAppId: App.GOOGLE_MEET,
    appId: App.GOOGLE,
    articleConfig: articleConfig.ConnectorGoogleWorkspace,
    guideSteps: PLATFORM_GUIDE_STEPS.GOOGLE.DEFAULT,
  }, {
    name: getAppDisplayName(App.GOOGLE_GMAIL),
    appId: App.GOOGLE_GMAIL,
    articleConfig: articleConfig.ConnectorGoogleGmail,
    infoPopover: <GmailConnectedEmailPopover />,
    additionalTitleText: '(Beta)',
    guideSteps: PLATFORM_GUIDE_STEPS.GOOGLE.EMAIL,
  }, {
    name: getAppDisplayName(App.ATLASSIAN_JIRA),
    logoAppId: App.ATLASSIAN_JIRA,
    appId: App.ATLASSIAN,
    articleConfig: articleConfig.ConnectorAtlassian,
  }, {
    name: getAppDisplayName(App.ATLASSIAN_CONFLUENCE),
    logoAppId: App.ATLASSIAN_CONFLUENCE,
    appId: App.ATLASSIAN,
    articleConfig: articleConfig.ConnectorAtlassian,
  }, {
    name: 'Atlassian JSM',
    logo: jsmIcon,
    appId: App.ATLASSIAN,
    articleConfig: articleConfig.ConnectorAtlassian,
  }, {
    name: getAppDisplayName(App.GITHUB),
    appId: App.GITHUB,
    articleConfig: articleConfig.ConnectorGitHub,
    guideSteps: [
      'Make sure you\'re an Owner for your Github account',
      <span key="github_step">
        Make sure your Organization&apos;s domain is verified (as instructed in Github&apos;s
        <ExternalLink to="https://docs.github.com/en/organizations/managing-organization-settings/verifying-or-approving-a-domain-for-your-organization">Verifying or approving a domain for your organization guide</ExternalLink>
        )
      </span>,
      'Link your Github Owner Account account with Akooda',
    ],
  }, {
    name: getAppDisplayName(App.MONDAY),
    appId: App.MONDAY,
    articleConfig: articleConfig.ConnectorMonday,
  }, {
    name: getAppDisplayName(App.FIGMA),
    appId: App.FIGMA,
    articleConfig: articleConfig.ConnectorFigma,
  }, {
    name: getAppDisplayName(App.NOTION),
    appId: App.NOTION,
    articleConfig: articleConfig.ConnectorNotion,
    guideSteps: [
      'Create a new akooda@yourcompany.com account in Google Suite',
      'Login to Notion as an admin',
      'Invite the akooda@yourcompany.com to Notion',
      'Give the Akooda Google Account the correct Account Roles and Permissions',
      'Sign into Akooda with your admin user',
      'Link your akooda@yourcompany.com with Akooda',
    ],
  }, {
    name: getAppDisplayName(App.INTERCOM),
    appId: App.INTERCOM,
    articleConfig: articleConfig.ConnectorIntercom,
    guideSteps: [
      'Make sure you\'re an Admin user in Intercom',
      'Link your Admin Intercom user with Akooda',
    ],
  }, {
    name: getAppDisplayName(App.BITBUCKET),
    appId: App.BITBUCKET,
    articleConfig: articleConfig.ConnectorBitBucket,
    guideSteps: [
      'Make sure you\'re an Admin user in Bitbucket',
      'Link your Admin slack user with Akooda',
    ],
  }, {
    name: getAppDisplayName(App.SALESFORCE),
    appId: App.SALESFORCE,
    articleConfig: articleConfig.ConnectorSalesforce,
    additionalTitleText: '(Beta)',
    guideSteps: [
      'Sign into Akooda with your admin user',
      'Link your SFDC admin account with Akooda',
    ],
  }, {
    name: getAppDisplayName(App.ZENDESK),
    appId: App.ZENDESK,
    articleConfig: articleConfig.ConnectorZendesk,
    additionalTitleText: '(Beta)',
  }, {
    name: getAppDisplayName(App.M365_EXCHANGE),
    logo: m365exchangeIcon,
    appId: App.M365_EXCHANGE,
    articleConfig: articleConfig.ConnectorM365,
    guideSteps: PLATFORM_GUIDE_STEPS.M365.EMAIL,
  }, {
    name: getAppDisplayName(App.M365),
    logo: m365Icon,
    appId: App.M365,
    articleConfig: articleConfig.ConnectorM365,
    additionalTitleText: '(Beta)',
    guideSteps: PLATFORM_GUIDE_STEPS.M365.DEFAULT,
  }, {
    name: getAppDisplayName(App.M365_SHAREPOINT),
    logoAppId: App.M365_SHAREPOINT,
    appId: App.M365,
    articleConfig: articleConfig.ConnectorM365,
    additionalTitleText: '(Beta)',
    guideSteps: PLATFORM_GUIDE_STEPS.M365.DEFAULT,
  }, {
    name: getAppDisplayName(App.M365_ONEDRIVE),
    logoAppId: App.M365_ONEDRIVE,
    appId: App.M365,
    articleConfig: articleConfig.ConnectorM365,
    additionalTitleText: '(Beta)',
    guideSteps: PLATFORM_GUIDE_STEPS.M365.DEFAULT,
  }, {
    name: getAppDisplayName(App.BOX),
    appId: App.BOX,
    articleConfig: articleConfig.ConnectorBox,
    guideSteps: [
      'Create a new akooda@yourcompany.com account in Box',
      'Give the new Akooda Box Account the correct Account Roles and Permissions',
      'Link the new Akooda Box Account account with Akooda',
      'Share the right files and drives with akooda@yourcompany.com',
    ],
  },
  {
    name: getAppDisplayName(App.ASANA),
    articleConfig: articleConfig.ConnectorAsana,
    appId: App.ASANA,
    guideSteps: [
      'Create a new akooda@yourcompany.com account in Google Suite',
      'Login to Asana as an admin',
      'Invite the akooda@yourcompany.com to Asana',
      'Give the Akooda Google Account the correct Account Roles and Permissions',
      'Sign into Akooda with your admin user',
      'Link your akooda@yourcompany.com with Akooda',
    ],
  },
  {
    name: getAppDisplayName(App.LINEAR),
    appId: App.LINEAR,
  },
  {
    name: getAppDisplayName(App.HUBSPOT),
    appId: App.HUBSPOT,
    guideSteps: [
      'Sign into Akooda with your admin user',
      'Link your Hubspot admin account with Akooda',
    ],
  },
  {
    name: getAppDisplayName(App.FRONT),
    appId: App.FRONT,
  },
  {
    name: getAppDisplayName(App.AIRTABLE),
    appId: App.AIRTABLE,
    articleConfig: articleConfig.ConnectorAirtable,
    guideSteps: [
      'Create a new akooda@yourcompany.com account in Airtable',
      'Give the new Akooda Airtable Account the correct Account Roles and Permissions',
      'Link the new Akooda Google Account account with Akooda',
      'Share the right workspace and bases with akooda@yourcompany.com',
    ],
  },
  {
    name: 'Okta',
    appId: AkoodaApiApp.OKTA,
    logo: oktaLogo,
  },
  {
    name: 'Bamboo',
    appId: AkoodaApiApp.BAMBOO,
    logo: bambooLogo,
  },
  {
    name: 'HiBob',
    appId: AkoodaApiApp.HIBOB,
    logo: hibobLogo,
  },
  {
    name: 'Shortcut',
    appId: AkoodaApiApp.SHORTCUT,
    logo: shortcutLogo,
  },
  {
    name: 'Miro',
    appId: App.MIRO,
    logo: miroLogo,
  },
];

export const ZOOM_CONNECTOR: Connector = {
  name: getAppDisplayName(App.ZOOM),
  appId: App.ZOOM,
};

export default CONNECTORS;
