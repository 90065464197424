import { useCallback, useMemo } from 'react';

import {
  useCustomAtomsFilters,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import Directionality from 'screens/platform/cross-platform-components/context/MasterFiltersContext/Directionality';
import getInitialDatesRange from 'screens/platform/directory/components/ProfileTabs/components/Filters/DateRangeUtils';
import DateUtils from 'utils/DateUtils';
import ObjectUtils from 'utils/ObjectUtils';

export default function useDefaultFilters(
  requiredEntity?: 'organizations' | 'topics' | 'people',
  entityValue?: string,
) {
  const { dispatchCustomAtomsFilters, customAtomsFilters } = useCustomAtomsFilters();

  const defaultFilters = useMemo(() => {
    let requiredEntityFilters = {};

    if (requiredEntity && entityValue) {
      requiredEntityFilters = {
        [requiredEntity]: [entityValue],
        requiredEntity: {
          type: requiredEntity,
          values: [entityValue],
        },
      };
    }

    return {
      datesRange: getInitialDatesRange(),
      directionality: [Directionality.EXTERNAL, Directionality.INTERNAL],
      ...requiredEntityFilters,
      includeUntagged: !(requiredEntity === 'topics'),
    };
  }, [requiredEntity, entityValue]);

  const handleResetFilters = useCallback(() => {
    dispatchCustomAtomsFilters({
      type: 'SET_FILTERS',
      payload: defaultFilters,
    });
  }, [defaultFilters]);

  const defaultFiltersWithDatesInUTC = {
    ...defaultFilters,
    datesRange: DateUtils.convertDatesRangeToUTC(defaultFilters.datesRange),
  };

  const areFiltersDefault = ObjectUtils.equals(customAtomsFilters, defaultFiltersWithDatesInUTC);

  return { areFiltersDefault, handleResetFilters };
}
