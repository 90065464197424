import { subMonths } from 'date-fns';

import { DatesRange } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';

export default function getInitialDatesRange(): DatesRange {
  const today = new Date();
  return {
    from: subMonths(today, 3),
    to: today,
  };
}
