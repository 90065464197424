import React from 'react';

import Button from 'common-ui-components/Button';
import useDefaultFilters from 'screens/platform/directory/components/ProfileTabs/hooks/DefaultFiltersHook';

import style from 'screens/platform/directory/components/ProfileTabs/components/Filters/FilterButton/style.module.scss';

interface Props {
  requiredEntity?: 'organizations' | 'topics' | 'people';
  entityValue?: string;
  disabled?: boolean;
}

export default function ClearFiltersButton({
  requiredEntity,
  entityValue,
  disabled,
}: Props) {
  const { handleResetFilters, areFiltersDefault } = useDefaultFilters(requiredEntity, entityValue);

  if (areFiltersDefault) return null;

  return (
    <Button
      onClick={handleResetFilters}
      transparent
      className={style.clearFilterButton}
      disabled={disabled}
    >
      Clear
    </Button>
  );
}
