export class NounsType {
  readonly singular: string;
  readonly plural: string;

  constructor(singular: string, plural: string) {
    this.singular = singular;
    this.plural = plural;
  }

  toLowerCase(): NounsType {
    return new NounsType(this.singular.toLowerCase(), this.plural.toLowerCase());
  }
}

const SingularPluralNouns = {
  person: new NounsType('Person', 'People'),
  department: new NounsType('Department', 'Departments'),
  organization: new NounsType('Organization', 'Organizations'),
  category: new NounsType('Category', 'Categories'),
  tag: new NounsType('Tag', 'Tags'),
  topic: new NounsType('Topic', 'Topics'),
  channel: new NounsType('Channel', 'Channels'),
  app: new NounsType('App', 'Apps'),
  result: new NounsType('Result', 'Results'),
  keyword: new NounsType('Keyword', 'Keywords'),

  day: new NounsType('Day', 'Days'),
  week: new NounsType('Week', 'Weeks'),

  interaction: new NounsType('Interaction', 'Interactions'),
  engagement: new NounsType('Engagement', 'Engagements'),
  conversation: new NounsType('Conversation', 'Conversations'),
  meeting: new NounsType('Meeting', 'Meetings'),
  contact: new NounsType('Contact', 'Contacts'),

  repository: new NounsType('Repository', 'Repositories'),
  project: new NounsType('Project', 'Projects'),
  workspace: new NounsType('Workspace', 'Workspaces'),
  drive: new NounsType('Drive', 'Drives'),
  database: new NounsType('Database', 'Databases'),
  inbox: new NounsType('Inbox', 'Inboxes'),
  table: new NounsType('Table', 'Tables'),
  brand: new NounsType('Brand', 'Brands'),
  tool: new NounsType('Tool', 'Tools'),
  team: new NounsType('Team', 'Teams'),

  other: new NounsType('Other', 'Others'),
};

export function getAmountLabel(count: number, noun: NounsType): string {
  const itemsLabel = count === 1 ? noun.singular : noun.plural;
  return `${count} ${itemsLabel}`;
}

export default SingularPluralNouns;
