import React, { Suspense } from 'react';

import Spinner from 'common-ui-components/Spinner';
import { PreLoadedCommChartData } from 'screens/platform/cross-platform-components/CommChartGraph';
import lazyWithRetry from 'utils/lazyWithRetry';

const CommChartGraph = lazyWithRetry(
  () => import('screens/platform/cross-platform-components/CommChartGraph'),
);

export default function LazyLoadedCommChartGraph({ rawData, handleEdgeClick, showOverlay }: {
  rawData?: PreLoadedCommChartData;
  handleEdgeClick?: (peopleIds: string[]) => void;
  showOverlay?: boolean;
}) {
  return (
    <Suspense fallback={<Spinner />}>
      <CommChartGraph
        rawData={rawData}
        handleEdgeClick={handleEdgeClick}
        showOverlay={showOverlay}
      />
    </Suspense>
  );
}
